import { ReactComponent as SellerIcon } from './seller.svg';
import { ReactComponent as DiscussionIcon } from './discussion.svg';
import { ReactComponent as BlogIcon } from './blog.svg';
import { ReactComponent as SupportIcon } from './support.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as YoutubeIcon } from './youtube.svg';
import { ReactComponent as LinkedinIcon } from './linkedin.svg';
import { ReactComponent as PinterestIcon } from './pinterest.svg';
import { ReactComponent as DiscordIcon } from './discord.svg';
import { ReactComponent as RedditIcon } from './reddit.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as FacebookIcon } from './facebook.svg';

export default {
	socialPart: {
		socials: {
			discord: {
				link: 'https://discord.gg/5rS6Tvd',
				title: 'Discord',
				icon: DiscordIcon,
			},
			twitter: {
				link: 'https://x.com/hostjane',
				title: 'X',
				icon: TwitterIcon,
			},
			reddit: {
				link: 'https://www.reddit.com/r/HostJane',
				title: 'Reddit',
				icon: RedditIcon,
			},
			youtube: {
				link: 'https://www.youtube.com/HostJane',
				title: 'YouTube',
				icon: YoutubeIcon,
			},
			facebook: {
				link: 'https://www.facebook.com/hostjanecom',
				title: 'Facebook',
				icon: FacebookIcon,
			},
			instagram: {
				link: 'https://www.instagram.com/hostjane/?hl=en',
				title: 'Instagram',
				icon: InstagramIcon,
			},
			pinterest: {
				link: 'https://www.pinterest.com/hostjane/',
				title: 'Pinterest',
				icon: PinterestIcon,
			},
			linkedIn: {
				link: 'https://linkedin.com/company/hostjane',
				title: 'LinkedIn',
				icon: LinkedinIcon,
			},
		},
		description:
			'Find a pool of experts at affordable prices or buy secure web hosting to launch your website in minutes!',
	},
	footLinks: {
		jip: {
			title: '#JaneIsPowerful',
			link: '/about-jane/',
			internal: true,
		},
		others: [
			{
				link: '/legal/tos/',
				internal: true,
				title: 'Site Terms',
			},
			{
				link: '/legal/ihra-antisemitism/',
				internal: true,
				title: 'Working Definition of Antisemitism',
			},
			{
				link: '/legal/privacy/',
				internal: true,
				title: 'Privacy',
			},
			{
				link: '/legal/cookie-policy/',
				internal: true,
				title: 'Cookies',
			},
			{
				link: '/sitemap/',
				internal: false,
				title: 'Sitemap',
			},
		],
		copyright: {
			link: '/',
			internal: true,
			title: `© ${new Date().getFullYear()} HostJane, Inc.`,
		},
	},
	columns: [
		{
			title: 'Marketplace',
			items: [
				{
					title: 'All Services',
					internal: false,
					link: 'https://www.hostjane.com/marketplace',
				},
				{
					title: 'Web / Mobile / Tech',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
				},
				{
					title: 'Design / Art & Video',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice',
				},
				{
					title: 'Bookings / Tutors',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/categories/online-tutors',
				},
				{
					title: 'Writing / Translation',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers',
				},
				{
					title: 'Business / Admin',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/categories/business-admin-support',
				},
				{
					title: 'Browse Skills',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/skills',
				},
				{
					title: 'Marketplace Rules',
					internal: true,
					link: '/legal/marketplace-terms',
				},
			],
		},
		{
			title: 'VPS & Cloud Hosting',
			items: [
				{
					title: 'See Pricing',
					internal: true,
					link: 'https://cloud.hostjane.com',
				},
				{
					title: 'VPS for Business',
					internal: true,
					link: 'https://cloud.hostjane.com/vps-hosting/',
				},
				{
					title: 'WordPress Hosting',
					internal: true,
					link: 'https://cloud.hostjane.com/wordpress/',
				},
				{
					title: 'Cloud Hosting',
					internal: true,
					link: 'https://cloud.hostjane.com/cloud/',
				},
				{
					title: 'Windows Hosting',
					internal: false,
					link: 'https://cloud.hostjane.com/windows/',
				},
				{
					title: 'Service Level Agreement',
					internal: true,
					link: '/legal/sla',
				},
				{
					title: 'Hosting Terms',
					internal: true,
					link: '/legal/hosting-terms',
				},
				{
					title: 'Contact Support',
					internal: true,
					link: 'https://www.hostjane.com/marketplace/contact',
				},
			],
		},
		{
			title: 'Help',
			items: [
				{
					title: 'Help Center',
					internal: false,
					link: 'https://help.hostjane.com/',
				},
				{
					title: 'Community',
					internal: true,
					link: 'https://www.hostjane.com/community',
				},
				{
					title: 'Telegram Support',
					internal: false,
					link: 'https://t.me/hostjanesupport',
				},
				{
					title: 'Telegram Channel',
					internal: false,
					link: 'https://t.me/hostjanecom',
				},
				{
					title: 'WordPress Plugin',
					internal: false,
					link: 'https://wordpress.org/plugins/tip-my-work-hostjane-payments/',
				},
				{
					title: 'WP Plugin Guide',
					internal: false,
					link: 'https://www.hostjane.com/wordpress',
				},
				{
					title: 'Network Status',
					internal: true,
					link: '/status',
				},
				{
					title: 'Disputes Center',
					internal: true,
					link: 'https://www.hostjane.com/marketplace/disputes/',
				},
				{
					title: 'Acceptable Use Policy',
					internal: true,
					link: '/legal/use-policy',
				},
				{
					title: 'Terms of Service',
					internal: true,
					link: '/legal/tos/',
				},
				{
					title: 'Contact Support',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/contact',
				},
			],
		},
		{
			title: 'Sell Your Skills',
			items: [
				{
					title: 'Become a Seller',
					internal: true,
					link: '/sell',
				},
				{
					title: 'Listing Center',
					internal: true,
					link: 'https://www.hostjane.com/marketplace/listings',
				},
				{
					title: 'Order Center',
					internal: true,
					link: 'https://www.hostjane.com/marketplace/orders',
				},
				{
					title: 'Account Settings',
					internal: true,
					link: 'https://www.hostjane.com/marketplace/settings',
				},
				{
					title: 'Transactions / Invoices',
					internal: true,
					link: 'https://www.hostjane.com/marketplace/reporting',
				},
				{
					title: 'Payout Preferences',
					internal: true,
					link: 'https://www.hostjane.com/marketplace/settings/payouts',
				},
				{
					title: 'Working on HostJane',
					internal: false,
					link: 'https://help.hostjane.com/sellers/',
				},
			],
		},
		{
			title: 'Keep money moving',
			mobileTitle: 'Keep money moving',
			items: [
				{
					title: 'Mission + Values',
					internal: true,
					link: '/about',
				},
				{
					title: 'Medium Stories',
					internal: false,
					link: 'https://medium.com/better-work',
				},
				{
					title: 'Better Tools',
					internal: false,
					link: 'https://help.hostjane.com/marketplace-faq/what-are-boards/',
				},
				{
					title: 'Ukraine War Album',
					internal: false,
					link: 'https://www.hostjane.com/hostjaneforukraine/',
				},
				{
					title: 'Official X',
					internal: false,
					link: 'https://x.com/hostjane',
				},
				{
					title: 'Celebs Promote',
					internal: false,
					link: 'https://www.youtube.com/watch?v=1qDe50faq0g&list=PLFlfImvkRB6nhw6Qt5EgXbQrdEz6dP4ln',
				},
				{
					title: 'Instagram',
					internal: false,
					link: 'https://www.instagram.com/hostjane',
				},
				{
					title: 'TikTok Shorts',
					internal: false,
					link: 'https://www.tiktok.com/@hostjane',
				},
				{
					title: 'Snapchat',
					internal: false,
					link: 'https://www.snapchat.com/add/hostjane?share_id=RkJFRUVF&locale=en_US',
				},
				{
					title: 'Discord Jobs',
					internal: false,
					link: 'https://discord.gg/5rS6Tvd',
				},
				{
					title: 'HJ Subreddit',
					internal: false,
					link: 'https://www.reddit.com/r/HostJane/',
				},
				{
					title: 'Tumblr Tags',
					internal: false,
					link: 'https://hostjane.tumblr.com/',
				},
			],
		},
	],
	connect: {
		title: 'Web hosting plans ',
		withIcons: [
			{
				icon: SellerIcon,
				title: 'Managed WordPress',
				link: 'https://cloud.hostjane.com/wordpress/',
				internal: false,
			},
			{
				icon: DiscussionIcon,
				title: 'Cloud Servers',
				link: 'https://cloud.hostjane.com/cloud/',
				internal: false,
			},
			{
				icon: BlogIcon,
				title: 'VPS Hosting',
				link: 'https://cloud.hostjane.com/vps-hosting/',
				internal: false,
			},
			{
				icon: SupportIcon,
				title: 'Windows Servers',
				link: 'https://cloud.hostjane.com/windows/',
				internal: false,
			},
		],
		smallOne: [
			{
				title: 'Quick Start Guides',
				link: 'https://help.hostjane.com/',
				internal: true,
			},
			{
				title: 'Contact Support',
				link: 'https://www.hostjane.com/marketplace/contact',
				internal: false,
			},
		],
		smallTwo: [
			
			{
				title: 'Login to Hosting',
				link: 'https://cloud.hostjane.com/hosting/login',
				internal: true,
			},
		],
	},
};
